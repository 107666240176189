.profile-page {
  .newsletter-info {
    &__text {
      padding: 10px;
      margin: 0;
      margin-#{$ldirection}: 10px;
    }
    &__header {
      padding-top: 10px;
      padding-left: 20px;
      text-indent: 0;
    }
  }
  .h_sms_promotions {
    padding-left: 20px;
    text-indent: 0;
    font-size: 12px;
  }
  .mobile_email_signup,
  .sms_promo_container,
  .sms_options_container {
    margin-#{$ldirection}: 10px;
    padding: 10px;
  }
}
