@mixin opc__updates__checkout-viewcart {
  .opc__cart {
    .checkout__content {
      #viewcart-panel {
        .viewcart-panel__content {
          margin-bottom: 30px;
          .checkout__subtitle,
          .qty-label {
            display: none;
          }
        }
        .top-viewcart-buttons {
          display: none;
        }
      }
      .continue-buttons {
        .btn {
          background-color: $color-pink;
        }
      }
    }
    .cart-items {
      .cart-item {
        &__qty {
          @include swap_direction(margin, 20px 3px 0 0);
        }
        &__total {
          @include swap_direction(margin, 25px 0 0 0);
          display: inline-block;
          float: $rdirection;
          padding-#{$rdirection}: 0;
        }
        &__desc-container {
          width: 67%;
        }
        .remove_link {
          $rdirection: -10px;
        }
      }
      .cart-item__desc-info {
        .color {
          @include swap_direction(padding, 5px 0 5px 0);
        }
      }
    }
    .bottom-viewcart-buttons {
      a.continue-shopping {
        display: none;
      }
    }
    #status_table {
      .order-summary__total {
        &-label,
        &-value {
          border-top: 1px solid $color-gray-border;
          padding-top: 15px;
        }
      }
    }
    .signin-to-see-cart {
      display: none;
    }
    .empty {
      .viewcart-panel {
        border-bottom: 0;
      }
      .empty-wrapper__link {
        @include swap_direction(margin, 0 0 15px 0);
        border: 2px solid $color-black;
        cursor: pointer;
        font-weight: 700;
        color: $color-black;
        text-transform: uppercase;
        line-height: 40px;
        .wrapper-link {
          text-decoration: none;
          cursor: pointer;
          text-align: center;
          display: block;
          color: $color-black;
        }
      }
      #viewcart-panel {
        .viewcart-panel__content {
          display: none;
        }
      }
    }
    .orders_items_text {
      display: none;
    }
    .checkout {
      .checkout__sidebar {
        display: block;
        margin: 0;
      }
      #order-summary-panel {
        #delivery-options {
          padding: 0px;
          .shipmethod-item-price {
            @include swap_direction(margin, 10px 0 5px 0);
            text-align: $rdirection;
            width: 32%;
            display: inline-block;
            float: $rdirection;
          }
          .shipmethod-item-label {
            @include swap_direction(margin, 10px 0 5px 0);
            width: 60%;
            display: inline-block;
          }
          .checkout__panel-header {
            display: none;
          }
          .ship-method-group {
            input[type='radio'] {
              &:checked {
                & ~ .label:before,
                & ~ label:before {
                  vertical-align: top;
                  margin-top: 14px;
                }
              }
              & ~ .label:before,
              & ~ label:before {
                vertical-align: top;
                margin-top: 14px;
              }
            }
            .disabled_shipmethod {
              .shipmethod-item-label,
              .shipmethod-item-price {
                color: $color-gray;
                cursor: not-allowed;
              }
              input[type='radio'] {
                &:checked {
                  & ~ .label:before,
                  & ~ label:before {
                    cursor: not-allowed;
                    color: $color-gray;
                  }
                }
                & ~ .label:before,
                & ~ label:before {
                  cursor: not-allowed;
                  color: $color-gray;
                }
              }
            }
          }
          #shipping-information-trigger {
            display: none !important;
          }
          .shipmethod-standard-label,
          .shipmethod-item-label,
          .shipmethod-item-price {
            font-family: $brandon-text-bold;
            margin-top: 10px;
          }
        }
        #status_table {
          .delivery-method-type {
            display: none;
          }
        }
      }
    }
    &.device-mobile {
      #sidebar {
        padding: 0;
        margin: 0;
      }
    }
  }
  .empty {
    .recommended-products-panel {
      display: block !important;
      padding-top: 25px;
      .recommended-product-items {
        .recommended-item {
          margin: 0px;
          overflow: hidden;
          position: relative;
          .product_image {
            display: block;
            float: $ldirection;
            width: 47%;
          }
          .description {
            @include swap_direction(padding, 0 0 0 16px);
            float: $ldirection;
            width: 48%;
            .product_name {
              a {
                padding: 0px;
                border: none;
              }
            }
            .column {
              padding: 0px;
            }
            .color {
              display: none;
            }
          }
          .add_button {
            @include swap_direction(margin, 15px 0px);
            width: 100%;
            float: $ldirection;
            input[type='submit'] {
              padding: 10px;
              border: 2px solid $color-pink;
              height: auto;
              line-height: 20px;
              text-align: center;
              background: none;
              color: $color-pink;
              font-size: 12px;
              letter-spacing: 1px;
              width: 100%;
              white-space: inherit;
            }
          }
        }
        .product_name {
          min-height: auto;
        }
      }
      .checkout__panel-title {
        font-size: 12px;
        white-space: nowrap;
        &:before,
        &:after {
          @include swap_direction(margin, 0 1.4em 0 1.2em);
          width: 30px;
        }
      }
      .formatted_price {
        @include swap_direction(padding, 10px 0);
        text-align: $ldirection;
        font-size: 14px;
        font-family: $brandon-text-bold;
      }
      .product_subname,
      .cart-item__size {
        display: none;
      }
      .icon-heart_outline {
        @include swap_direction(padding, 10px 0 20px 5px);
        font-size: 15px;
        text-decoration: none;
      }
      .slick-prev {
        #{$ldirection}: -3px;
      }
      .slick-next {
        #{$rdirection}: -6px;
        &:before {
          position: relative;
          top: auto;
          #{$rdirection}: auto;
          #{$ldirection}: auto;
          bottom: auto;
        }
      }
      .slick-prev,
      .slick-next {
        top: 32%;
        width: 18px;
        height: 22px;
        &:before {
          font-size: 22px;
        }
      }
      .slick-slider {
        margin-bottom: 0;
      }
    }
  }
  .viewcart {
    .payments-icon {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0 15px;
      margin: 70px auto 0;
      .logo {
        background-position: center;
        background-size: 100%;
        margin: 0 7px;
        max-width: 40px;
        width: 100%;
        height: 20px;
      }
    }
    .checkout-icon {
      height: 110px;
      .continue-checkout {
        &.mobile_sticky_bottom {
          margin-bottom: 50px !important;
        }
      }
    }
  }
}
