// offers
.offer-code-panel {
  .offer-code-panel__title {
    margin-bottom: 0px;
  }
  .offer-code-message {
    display: none;
  }
  .one-offer-only {
    @include swap_direction(margin, 18px 0 14px 0);
    display: block;
    span {
      display: inline;
      float: $rdirection;
      .offer-code__see-all-offers {
        border-width: 0 0 2px 0;
        border-style: none none solid none;
        margin-top: 0;
        padding: 0;
      }
    }
  }
  input[type='text'] {
    width: 100%;
    margin: 0;
    text-transform: capitalize;
  }
}
.messages {
  .error_messages {
    padding: 12px;
    font-size: 14px;
    .offer_success {
      color: $color-green;
    }
  }
}
