.sign-in-page {
  .return-user,
  .new-account {
    input {
      width: 100%;
      margin-top: 10px;
      &[type='button'] {
        border-radius: 0;
      }
    }
  }
}

.sticky-add-to-bag__status {
  &.sticky-add-to-bag__status--shaded,
  &.sticky-add-to-bag__status--non-shaded {
    .product__inventory-status {
      display: none;
    }
  }
}

.sticky-add-to-bag__status--non-shaded {
  .product__inv-status-item {
    margin-#{$ldirection}: 50%;
    padding-top: 20px;
    line-height: 43px;
  }
}

.tiny-waitlist-overlay {
  &.bis-signup {
    .email_input {
      width: calc(100% - 2px);
    }
  }
}

#cs_chat {
  margin-top: 1rem;
  a {
    color: $color-black;
    text-decoration: underline;
    font-size: 14px;
  }
  a:hover {
    color: $color-pink;
  }
  &.gray {
    a {
      color: $color-warm-grey;
      text-decoration: underline;
    }
  }
}

.customer-service-section {
  h2 {
    text-align: center;
  }
  #customer_service_chat {
    display: block;
    a {
      bottom: 20px;
    }
  }
}

.address-form {
  .default_ship_container {
    span.label-content {
      display: inline;
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.06em;
    }
  }
}

.section-store-locator {
  .responsive-tabs--store-locator {
    .resp-tab-item:first-child {
      width: 60%;
      letter-spacing: 0.08em;
    }
    .resp-tab-item:last-child {
      display: none;
    }
  }
}

.saved-payments__content {
  padding: 0 10px;
  .saved-payment__item {
    margin-bottom: 20px;
  }
}

.payment-delete__header {
  font-family: $body-font-bold;
  font-size: 14px;
  text-transform: uppercase;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid $color-black;
}

.homepage_evergreen_services_body {
  #cs_chat {
    a {
      float: none;
      letter-spacing: 0.24em;
      font-size: 12px;
      padding: 21px 0;
      text-decoration: none;
    }
  }
}

html[lang='hi-IN'],
body.hi_IN,
body.i18n-hi-e-IN {
  * {
    letter-spacing: normal !important;
  }
  .product-brief__header {
    font-weight: 600 !important;
  }
}

.search-results__header {
  .search-form__fields {
    .search-form__results-count {
      line-height: 2.4;
    }
  }
}

.section-esearch {
  #search-wrapper {
    #search-results {
      .results-header {
        display: none;
      }
    }
    #product-results {
      float: none;
      border: none;
    }
  }
}

.content-formatter {
  .content-formatter__rendered > .content-block--basic-tout {
    .content-block__content {
      .content-block__content-text {
        .content-block__line--content--1 {
          .custom-text,
          p {
            font-weight: 700;
          }
        }
      }
    }
  }
}

.product-full__offer {
  font-weight: 700;
}

.product-foundation-vto {
  &__mobile-ctrl {
    .container {
      .product-full__add-button {
        display: inline-block !important;
      }
    }
  }
}
