@mixin opc__updates__checkout-payment {
  .opc__payment {
    .checkout {
      .checkout-buttons-container {
        a {
          width: 93% !important;
        }
        &.trans_detail_item {
          width: auto;
        }
      }
      &-payment_title {
        @include swap_direction(margin, 4px 0 0 0);
        font-family: $brandon-text-bold;
        font-weight: normal;
        line-height: 18px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        text-indent: 0;
        padding-bottom: 5px;
        border-bottom: 0;
        background: $color-white;
        border-top: 0;
        text-align: center;
        font-size: 21px;
      }
      #review-address-info {
        .expando-block {
          .expando-block__icon {
            transform: rotate(360deg);
          }
          &.expando-block--expanded {
            .expando-block__icon {
              transform: rotate(180deg);
            }
          }
        }
      }
      #viewcart-panel {
        &.expando-block {
          .expando-block__icon {
            transform: rotate(360deg);
          }
          &.expando-block--expanded {
            .expando-block__icon {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
    .full_divider {
      @include swap_direction(padding, 10px 0);
      border-bottom: 1px solid $color-black;
    }
    .order-summary-panel {
      background: $color-gray-background;
      border-top: 1px solid $color-black;
      margin: 0;
      &__title {
        @include swap_direction(padding, 8px 20px 15px 20px);
        border-bottom: 1px solid $color-gray-border;
        margin: 0;
      }
      .orders_items_text {
        display: none;
      }
      .order-summary__total {
        &-label,
        &-value {
          border-top: 1px solid $color-gray-border;
        }
      }
    }
    .order-summary__content {
      padding: 10px;
    }
    .checkout__sidebar {
      #offer-code-panel {
        background: $color-gray;
      }
    }
    .checkout {
      .expando-block {
        border-bottom: 0;
      }
      .checkout__sidebar {
        #offer-code-panel {
          margin: 10px;
        }
      }
    }
    .shipping-panel,
    .viewcart-panel {
      margin: 10px;
    }
    #review-address-info,
    .viewcart-panel__content {
      padding: 0;
    }
    &#billing {
      .checkout {
        p {
          @include swap_direction(margin, 0px 0px 10px 0px);
        }
        h3 {
          &.checkout__panel-title-container {
            @include swap_direction(margin, 0px 0px 20px 0px);
          }
        }
      }
    }
    .payment-option-container {
      @include swap_direction(margin, 15px 0px 0px 0px);
      .payment-option-block {
        .group1 {
          @include swap_direction(padding, 11px 10px);
          font-weight: bold;
          font-size: 12px;
          text-transform: uppercase;
          width: 50%;
          display: inline-block;
          border-top: 1px solid $color-gray-border;
          img {
            @include swap_direction(margin, -3px 0px 0px 0px);
            width: 45px;
            float: $rdirection;
            height: 35px;
          }
          &:nth-child(odd) {
            border-#{$rdirection}: 1px solid $color-gray-border;
          }
          input[type='radio'] {
            ~ label::before {
              font-size: 18px;
            }
          }
        }
        .payment-option-header {
          @include swap_direction(padding, 20px 20px);
          border-top: 1px solid $color-black;
          font-weight: bold;
          font-size: 18px;
          img {
            float: $rdirection;
          }
          &.checkout__panel-title {
            border-bottom: none;
          }
        }
      }
    }
    .payment-type-panel {
      .group2,
      .group3 {
        @include swap_direction(padding, 11px 10px);
        border-bottom: 1px solid $color-black;
        display: inline-block;
        font-weight: bold;
        font-size: 12px;
        text-transform: uppercase;
        line-height: 22px;
        width: 100%;
        img {
          float: $rdirection;
          width: 50px;
        }
      }
      h2 {
        &.checkout__panel-title {
          text-align: center;
          border: none;
          padding-bottom: 0px;
        }
      }
    }
    .co-hidden {
      display: none;
    }
    .payment-select {
      .offer-code-panel,
      #links-panel {
        display: none;
      }
    }
    #payment-info-panel {
      border-bottom: none;
    }
    .order_status {
      .change_link {
        float: $rdirection;
        color: $color-pink;
        position: absolute;
        #{$rdirection}: 0;
        text-transform: uppercase;
        margin-top: 13px;
        display: inline-block;
      }
      #offer-code-panel {
        @include swap_direction(padding, 15px 20px 0 20px);
        border: 0;
        &.expando-block {
          &.expando-block--expanded {
            .expando-block__icon {
              transform: rotate(0deg);
            }
          }
          .expando-block__icon {
            transform: rotate(270deg);
          }
        }
      }
      .co_default {
        display: none;
      }
      .order_status_panel {
        border-bottom: 1px solid $color-black;
        padding-bottom: 15px;
      }
      #review-address-info {
        .transaction-item {
          .expando-block {
            &.expando-block--expanded {
              .expando-block__content {
                height: auto;
                display: block;
                padding: 0;
                max-width: 60%;
              }
              .change_link {
                display: block;
              }
            }
            .expando-block__content {
              height: 0;
              display: none;
            }
            .change_link {
              display: none;
            }
          }
        }
      }
      header {
        &.order__status {
          display: none;
        }
      }
    }
  }
}
