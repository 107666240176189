@mixin opc__updates__signin-panel {
  .opc__signin {
    .sign-in-page {
      padding: 0;
    }
    .checkout-required {
      display: none;
    }
    .site-footer {
      .site-footer__wrap {
        padding-bottom: 0;
      }
    }
    .signin-panel__title {
      letter-spacing: 0.12em;
      text-indent: inherit;
      margin: 0;
      text-align: center;
    }
    .btn {
      background-color: $color-pink;
      color: $color-white;
      padding: 15px;
      height: auto;
      line-height: 1;
      width: 100%;
    }
    .new-account {
      &__button {
        background-color: $color-white;
        color: $color-black;
        border: 2px solid $color-black;
        padding: 15px;
        height: auto;
        line-height: 1;
        width: 100%;
      }
      a {
        color: $color-black;
      }
      .info-icon {
        color: $color-white;
      }
      #registration_short {
        .accept-privacy-info {
          margin: 0;
        }
      }
      .newsletter-info {
        &__header,
        .info-link,
        &__text--new,
        &__text,
        .pc_email_promo_terms_container {
          display: none;
        }
        &__item,
        &__text {
          margin: 0px;
          padding: 0px;
        }
      }
    }
    .return-user__link {
      @include swap_direction(margin, 20px 0 10px 0);
      text-transform: uppercase;
      display: inline-block;
      font-weight: 600;
      letter-spacing: 0.12em;
    }
    .show-password {
      position: relative;
      label {
        visibility: hidden;
      }
      span {
        visibility: visible;
        position: absolute;
        right: 5px;
        top: 20px;
        border-bottom: 1px solid $color-gray-border;
        &:visited {
          border: 0;
        }
      }
    }
    &#signin {
      .return-user,
      .new-account,
      .account__return-user {
        @include swap_direction(margin, 27px 0 100px 0);
        .return-user__item,
        .new-account__item {
          margin-bottom: 10px;
          input[type='text'],
          input[type='password'],
          input[type='email'] {
            height: calc(3em - -2px);
            width: 100%;
            margin-top: 0;
            &::placeholder {
              text-transform: uppercase;
            }
          }
          &.show-password {
            margin-bottom: 0;
            outline: 0 !important;
          }
        }
        .new-account__text {
          @include swap_direction(margin, 20px 0 20px 0);
          text-transform: uppercase;
          font-weight: 600;
        }
        .return-user__text {
          @include swap_direction(margin, 18px 0px);
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      .new-account {
        margin-top: 60px;
      }
      .sign-in-legal-link,
      #forgot-password {
        color: $color-pink;
        font-size: 16px;
        font-weight: bold;
        margin-top: 15px;
        padding: 0px;
      }
      .signin-panel__title {
        @include swap_direction(padding, 0 20px 16px);
      }
    }
  }
}
