@mixin bold-reset {
  font-family: $bbtext-bold;
  font-weight: normal;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
.h2,
.p-w-r {
  font-family: $bbtext-regular;
}

.popup-offer-overlay-cbox {
  .popup-offer {
    &__body,
    input[type='text'] {
      font-family: $bbtext-regular;
    }
  }
}

.checkout-co-enabled {
  .checkout {
    .viewcart-panel__title,
    .checkout__panel-title {
      font-family: $bb-sans;
    }
  }
}
