@mixin opc__updates__checkout-samples {
  .opc__samples {
    .samples-panel__content {
      .samples-top {
        .samples-buttons {
          display: none;
        }
      }
      .samples-buttons {
        &.bottom {
          display: block;
        }
      }
    }
    .samples-buttons {
      a#no-thanks {
        display: none;
      }
    }
    #viewcart-panel {
      .qty-label {
        display: none;
      }
    }
    .samples-page {
      .samples-panel {
        .sample-select-button:hover {
          background: $color-black;
          border-color: $color-black;
        }
        .selected,
        .selected:hover {
          background: $color-pink;
          border-color: $color-pink;
        }
      }
    }
    .deactivate {
      .sample-select-button,
      .sample-select-button:hover {
        opacity: 0.3;
        cursor: default;
        background-color: $color-black;
        border-color: $color-gray-light;
      }
    }
  }
}
