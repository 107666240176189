.address-overlay-shipping {
  .default_ship_container {
    input[type='checkbox'] + label span.label-content {
      display: initial;
    }
    input[type='checkbox'] + * + label span.label-content {
      display: initial;
    }
  }
}
