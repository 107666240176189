@mixin bolded-point($stage) {
  .checkout-progress {
    li.checkout-progress__#{$stage} {
      font-family: $bbtext-bold;
    }
  }
}

.checkout {
  .checkout-progress {
    li {
      float: $ldirection;
      margin: 24px 24px 0;
      list-style-type: decimal;
      width: auto;
    }
  }
  .checkout__sidebar {
    section {
      @include checkout-sidebar-panel;
      margin-bottom: 10px;
    }
    .links_list .link {
      display: block;
      margin-bottom: 10px;
      border: 0 none;
    }
    .continue-buttons {
      .disabled.continue-checkout {
        background-color: $color-light-gray;
      }
    }
    .shipping-panel__title {
      font-size: 2em;
      letter-spacing: 1px;
    }
  }
  .checkout__content {
    .cart-items {
      .sub__total {
        margin-top: 20px;
        text-align: right;
        text-transform: uppercase;
        strong {
          margin-left: 8%;
        }
      }
    }
    .discount-old-price {
      text-decoration: line-through;
    }
    #viewcart-panel,
    #bottom-viewcart-buttons {
      .viewcart-buttons-panel {
        .continue-buttons {
          .disabled.continue-checkout {
            background-color: $color-light-gray;
          }
        }
      }
    }
    .postal_code_container {
      @include breakpoint($medium-up) {
        .field {
          max-width: 50%;
          float: left;
        }
        .cod-available-container {
          max-width: 50%;
          float: left;
          padding-top: 7px;
          padding-left: 5px;
        }
      }
    }
    .address_form_container {
      .address-form__content {
        .title_container {
          margin-bottom: 15px;
        }
      }
    }
  }
  select {
    margin-bottom: 20px;
    height: 32px;
  }
  .payment_method_container {
    &.radio-buttons {
      img {
        max-height: 50px;
      }
    }
  }
  .legal_txt {
    margin-bottom: 20px;
  }
  .payu-options {
    .saved-option {
      margin-top: 15px;
      .token-detail {
        display: none;
        line-height: 25px;
        margin-bottom: 10px;
      }
    }
    .other-option {
      .field-container {
        margin-bottom: 20px;
      }
    }
  }
}

.checkout-co-enabled {
  &#signin {
    .pg_wrapper {
      .sign-in-page {
        &.checkout {
          .new-or-returning-links {
            .new-customer,
            .returning-customer {
              @include bold-reset;
            }
          }
        }
      }
    }
  }
}

.shipping {
  @include bolded-point(shipping);
}

.device-mobile {
  .upi-handles {
    height: 42px;
    padding-#{$ldirection}: 0;
  }
  label {
    display: block;
    white-space: normal;
    .text-label {
      margin-#{$ldirection}: 20px;
    }
    .payment-option-container {
      .payment-option-text-container {
        width: 70%;
        text-align: left;
        display: inline-block;
        .payment-option-payu-txt {
          margin-#{$ldirection}: 20px;
          font-size: 1em;
        }
      }
      .payment-option-image-container {
        width: 50px;
        text-align: right;
        clear: left;
        display: inline-block;
        vertical-align: middle;
        img {
          margin-#{$ldirection}: 12px;
          height: 19px;
          width: 37px;
        }
      }
    }
  }
  .email_promotions {
    .sms_promo_container {
      padding: 15px 0;
    }
  }
}

.billing {
  @include bolded-point(billing);
  #checkout_complete {
    #payment-info-panel {
      .payment_method_container {
        .payment-type {
          label {
            display: flex;
            align-items: center;
            height: 60px;
            padding: 20px 0;
            .text-label {
              margin-#{$ldirection}: 20px;
            }
            .payment-option-container {
              width: 100%;
              .payment-option-text-container {
                width: 70%;
                text-align: left;
                display: inline-block;
                .payment-option-payu-txt {
                  margin-#{$ldirection}: 20px;
                }
              }
              .payment-option-image-container {
                width: 25%;
                text-align: right;
                clear: left;
                display: inline-block;
                vertical-align: middle;
                height: 1em;
                img {
                  margin-#{$ldirection}: 12px;
                  height: 19px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.upi-providers {
  width: 96%;
}

.upi-handles {
  line-height: 1.7;
  padding-#{$ldirection}: 0;
}

.text-select-container {
  width: 100%;
  .text-container {
    width: 53%;
    display: inline-block;
    margin-top: 1px;
    input {
      width: 100%;
    }
  }
  .select-container {
    width: 35%;
    display: inline-block;
    margin-#{$ldirection}: 0;
  }
}

.confirm {
  @include bolded-point(confirm);
}

.form-item {
  &.card-store {
    margin-bottom: 15px;
    .card_name_field {
      margin-top: 15px;
    }
  }
}
