@mixin opc__updates__checkout-shipping {
  .opc__shipping {
    .address-form__item {
      padding: 0;
      margin: 8px 0;
    }
    .info-link,
    #please_note_changes,
    .checkout-required {
      display: none;
    }
    .address_controls {
      .radio {
        @include swap_direction(margin, 0 0 10px 0);
      }
    }
    #choose-address {
      .choose-book_txt {
        display: none;
      }
      .delivery_address {
        padding-#{$ldirection}: 5px;
        margin-top: -3px;
        &::first-line {
          font-weight: bold;
          text-transform: capitalize;
        }
      }
      .change_link {
        float: $rdirection;
        color: $color-pink;
        border-bottom: 1px solid $color-pink;
        position: absolute;
        #{$rdirection}: 20px;
        text-transform: uppercase;
      }
      .radio {
        label {
          display: flex;
        }
      }
    }
    .billing-info,
    .delivery-panel {
      .change_link {
        float: $rdirection;
        color: $color-pink;
        border-bottom: 1px solid $color-pink;
        text-transform: uppercase;
      }
    }
    .full_divider {
      @include swap_direction(padding, 20px);
      border-bottom: 1px solid $color-black;
    }
    .shipping-panel__title {
      font-size: 21px;
      text-align: center;
      display: block;
      background: none;
      border: none;
      letter-spacing: 0.1em;
    }
    .checkout {
      .checkout__sidebar {
        #offer-code-panel {
          @include swap_direction(padding, 15px 15px 0 15px);
          @include swap_direction(margin, 0 0 15px 0);
          background: $color-gray;
          border: 0;
        }
        .shipping_sign-in-legal-link {
          .info-link {
            @include swap_direction(padding, 0 24px !important);
            display: block;
            margin-bottom: 25px;
          }
        }
        #links-panel {
          margin-bottom: 25px;
        }
      }
      #billing_address_form_container {
        padding: 20px;
        border-top: 1px solid $color-black;
        margin-top: 20px;
        border-bottom: 0;
      }
      #bill_to_shipping_container {
        @include swap_direction(padding, 0 20px 20px);
        label {
          font-weight: bold;
        }
      }
      .default_ship_container {
        label {
          font-weight: bold;
        }
      }
      .gift-wrapper-block {
        padding: 0;
        background: $color-gray-background;
        .checkout__panel-title {
          @include swap_direction(padding, 15px 20px);
          margin: 0;
        }
        .expando-block__content {
          padding: 0px;
        }
        .gift-wrapper-content-block {
          padding: 20px;
        }
      }
      #offer-code-panel {
        &.expando-block {
          .expando-block__icon {
            transform: rotate(270deg);
          }
        }
        &.expando-block--expanded {
          .expando-block__icon {
            transform: rotate(180deg);
          }
        }
      }
      .viewcart-panel {
        &.expando-block {
          .expando-block__icon {
            transform: rotate(360deg);
          }
        }
        &.expando-block--expanded {
          .expando-block__icon {
            transform: rotate(180deg);
          }
        }
      }
    }
    .newsletter-info__item,
    .sms-promotions__item {
      @include swap_direction(padding, 15px 0 15px 0);
      margin: 0;
    }
    .email_promotions {
      @include swap_direction(padding, 15px 0);
    }
    .city_container {
      margin-bottom: 10px;
    }
    #address-section-container {
      .address_form_container {
        border: 0;
        .radio > div {
          margin-bottom: 10px;
        }
      }
    }
    #shipping-address,
    #billing-address-info-panel {
      .default_ship_container,
      .address_form_container {
        .label-content {
          font-family: $brandon-text-bold;
        }
        input[type='checkbox'] {
          ~ label {
            &:before {
              margin-top: -5px;
            }
          }
        }
      }
      .billing-address-section {
        &.border-top {
          border-top: 1px solid $color-black;
        }
      }
    }
    .checkout-email {
      margin-bottom: 15px;
      border-bottom: 1px solid $color-light-gray;
      border-top: 2px solid $color-black;
      background-color: $color-gray-background;
      padding: 20px;
    }
    .order-summary-panel {
      background: $color-gray-background;
      border: 0;
      padding: 0;
      margin: 0;
      &__title {
        @include swap_direction(padding, 15px 20px);
      }
      .orders_items_text {
        display: none;
      }
      .order-summary__total {
        &-label,
        &-value {
          border-top: 1px solid $color-gray-border;
        }
      }
      .order_status {
        display: flex;
        flex-direction: column;
      }
      .checkout__sidebar {
        order: 2;
      }
      .viewcart-panel {
        order: 3;
      }
    }
    .remove_link {
      font-size: 25px;
      border: 0;
      position: absolute;
      top: 0;
      #{$rdirection}: -15px;
      width: auto;
    }
    .view-cart-panel__title {
      @include swap_direction(padding, 0 15px);
    }
    .viewcart-panel {
      .cart-item {
        &__price {
          margin: 0;
          padding-top: 0;
        }
      }
    }
    .delivery-panel {
      .address_controls {
        display: none;
      }
    }
    .shipping-address-info {
      #address_form_container {
        border: none;
        padding: 20px;
      }
    }
    .billing-info {
      .sub-block {
        margin-bottom: 0;
      }
      .guest-header {
        border-top: 1px solid $color-black;
      }
    }
    .checkout__panel-header {
      float: inherit;
    }
    .click-and-collect {
      .local-collection-link {
        float: $rdirection;
        color: $color-pink;
        border-bottom: 1px solid $color-pink;
        text-decoration: none;
        font-size: 14px;
        font-weight: normal;
        font-family: $brandon-text;
      }
      .show-collection-point {
        padding: 0;
        border: 0;
        margin: 0;
      }
      .checkout-buttons {
        background-color: $color-black;
      }
      .delivery_address-header {
        margin-top: 20px;
      }
    }
    #delivery-options {
      .shipform_fields {
        .ship-method-group-label {
          width: 100%;
          float: $ldirection;
          span {
            @include swap_direction(margin, 10px 0);
            display: inline-block;
            width: 47%;
            input[type='radio'] {
              visibility: hidden;
              position: absolute;
              &:checked ~ label:before,
              & ~ label:before {
                display: none;
              }
              &:checked + label {
                border: 2px solid $color-pink;
                color: $color-pink;
              }
            }
            label {
              @include swap_direction(padding, 35% 0 5% 0);
              display: block;
              width: 100%;
              font-size: 0.8em;
              border: 1px solid $color-border-gray;
              text-align: center;
              text-transform: uppercase;
            }
          }
          .home_delivery_option {
            label {
              background: url(/media/images/global/home.png) no-repeat center 30%;
            }
            input[type='radio']:checked + label {
              background: url(/media/images/global/home_active.png) no-repeat center 30%;
            }
          }
          .click_and_collect_option {
            float: $rdirection;
            label {
              background: url(/media/images/global/map_point.png) no-repeat center 30%;
            }
            input[type='radio']:checked + label {
              background: url(/media/images/global/map_point_active.png) no-repeat center 30%;
            }
            .click-and-collect {
              span {
                display: none;
              }
            }
          }
        }
        select {
          margin: 0;
        }
        .ship-method-collection-group + a {
          padding-top: 8px;
          display: none;
        }
      }
      .shipmethod-standard-label {
        @include swap_direction(padding, 15px 20px);
        @include swap_direction(margin, 0 calc(-100vw / 2 + 100% / 2) 10px);
        background: $color-gray-background;
      }
      .hazmat_shipping_message {
        display: none;
      }
    }
    #address-section-container {
      #billing_address_form_container {
        border-top: 1px solid $color-black;
        margin-top: 20px;
        border-bottom: 0;
        padding: 20px;
      }
    }
    #giftwrap-info-panel {
      border-top: 1px solid $color-black;
      #gitft-info-panel {
        border-bottom: 0px;
      }
    }
    .ship-method-group-label {
      .checkout__panel-header {
        float: $ldirection;
        width: 100%;
      }
    }
    .add_new_address {
      label {
        font-family: $brandon-text-bold;
        text-transform: uppercase;
        letter-spacing: 0.1em;
      }
    }
    .co_default {
      font-family: $brandon-text-bold;
      color: $color-pink;
      text-transform: uppercase;
    }
    .billing-address-display {
      padding: 20px;
    }
    .shipping_address_global {
      padding: 20px;
    }
    .terms_conditions_container {
      padding: 20px;
    }
    .order-summary-panel__title {
      border-top: 1px solid $color-black;
    }
    .new_shipping_address {
      font-size: 16px;
      padding-#{$ldirection}: 5px;
    }
  }
}
