.order-details {
  .order-products {
    .cart-header {
      font-size: 13px;
      .qty {
        @include breakpoint($medium-up $landscape-up) {
          width: 27%;
        }
      }
      .products {
        width: 16%;
      }
    }
  }
  .cart-item {
    &__price {
      width: 21%;
      float: $ldirection;
    }
    &__total {
      float: $rdirection;
      text-align: $rdirection;
      padding-top: 10px;
      @include breakpoint($medium-up) {
        padding-top: 0;
        width: 9%;
      }
    }
    &__desc {
      margin-right: 20px;
      @include breakpoint($tablet-down) {
        width: 25%;
      }
    }
    &__replenishment {
      padding: 0;
    }
  }
  .order-totals {
    &__section {
      table {
        font-size: 13px;
        td {
          text-align: $rdirection;
        }
      }
    }
  }
  &-page__loyalty-note {
    padding-top: 20px;
    clear: both;
    text-align: $ldirection;
  }
}

.past-purchases {
  &-data-item {
    &__item {
      text-indent: 5px;
      @include breakpoint($medium-up) {
        text-indent: 0;
      }
      &.created-date {
        text-indent: 5px;
        @include breakpoint($medium-up) {
          text-indent: 0;
        }
      }
    }
  }
}
