/* Progress Bar */
@mixin opc__updates__checkout-progress-bar {
  &:before {
    background-color: $color-pink;
    color: $color-pink;
  }
  a {
    color: $color-pink;
    &:hover,
    &:visited {
      color: $color-pink;
    }
  }
}

@mixin opc__updates__checkout-shared {
  .opc__cart,
  .opc__samples,
  .opc__signin,
  .opc__confirm,
  .opc__wp_samples {
    .site-utils {
      &__item--search,
      &__item--cart,
      &__icon--hamburger,
      &__icon--search {
        display: inline-block;
      }
      &__item--stores {
        display: none;
      }
    }
    .mobile-checkout-back {
      display: none;
    }
    .site-header {
      .site-utils__links {
        .site-utils__menu {
          .site-utils__item--stores {
            display: none;
          }
        }
      }
    }
  }
  .opc__samples,
  .opc__signin {
    .site-footer {
      .bb_copyright {
        @include swap_direction(padding, 30px 20px 75px 20px);
      }
    }
  }
  .opc__cart {
    .checkout {
      .checkout__content {
        overflow: hidden;
      }
      .expando-block {
        border-bottom: none;
      }
    }
  }
  .opc__shipping {
    .co_shipping_hidden {
      display: none;
    }
  }
  #edit_address,
  #add_address {
    .google_autocomplete {
      display: none;
    }
  }
  .opc__shipping,
  .opc__samples,
  .opc__payment,
  #billing,
  .opc__review,
  #confirm {
    .checkout-progress-bar {
      margin-#{$ldirection}: -20px;
      &__list-item {
        word-wrap: break-word;
      }
    }
    .pg_wrapper {
      padding-top: 15px;
    }
  }
  .opc__shipping,
  .opc__samples,
  .opc__review {
    .checkout-progress-bar {
      &__shipping {
        @include opc__updates__checkout-progress-bar();
      }
    }
  }
  .opc__review {
    .checkout {
      .checkout__sidebar {
        #links-panel {
          border-bottom: none;
        }
      }
    }
  }
  .opc__payment,
  #billing {
    .checkout-progress-bar {
      &__shipping,
      &__payment {
        @include opc__updates__checkout-progress-bar();
      }
    }
  }
  #confirm {
    .checkout-progress-bar {
      &__shipping,
      &__payment,
      &__review {
        @include opc__updates__checkout-progress-bar();
      }
    }
    .promo-bca-panel,
    .site-footer,
    .site-footer-contact,
    .site-footer-toggle-device,
    .site-footer__column,
    .site-footer__main,
    .checkout__sidebar {
      display: none;
    }
  }
  .checkout {
    .checkout__sidebar {
      #offer-code-panel {
        @include swap_direction(margin, 0 0 45px 0);
        @include swap_direction(padding, 16px 15px 0);
        .offer-code-panel__content {
          .offer_submit {
            height: 40px;
          }
        }
      }
      #links-panel {
        border-bottom: 1px solid $color-black;
        border-top: 1px solid $color-black;
        margin-bottom: 40px;
        display: block;
        border-#{$ldirection}: 0;
        border-#{$rdirection}: 0;
        background-color: $color-gray-background;
        padding: 0;
        .checkout__panel-title {
          @include swap_direction(padding, 15px 20px);
        }
      }
      .expando-block__header {
        @include swap_direction(margin, 0);
      }
      .links_list {
        @include swap_direction(padding, 10px 20px 20px 20px);
      }
    }
  }
  .error_messages {
    &.error {
      li {
        word-wrap: break-word;
      }
    }
  }
  .opc__cart,
  .opc__samples,
  .opc__review,
  .opc__signin,
  .opc__shipping,
  .opc__payment {
    .footer-link-container {
      @include swap_direction(padding, 30px 20px);
      float: $ldirection;
      width: 100%;
      margin-bottom: 20px;
      border-bottom: 1px solid $color-gray-border;
      display: block;
      .footer-link {
        @include swap_direction(padding, 15px 0);
        width: 48%;
        display: inline-block;
        float: $ldirection;
        text-align: center;
        border: 1px solid $color-border-gray;
        font-size: 12px;
        font-weight: bold;
        background-color: $color-white;
        text-transform: uppercase;
        height: 60px;
        &.contact-link {
          float: $rdirection;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }
      }
      .icon-phone,
      .icon-livechat,
      .icon-email {
        margin-#{$rdirection}: 5px;
      }
    }
    .secure-content {
      @include swap_direction(padding, 11px 0);
      font-weight: bold;
      display: block;
    }
    .site-footer {
      float: none;
      .secure-content {
        @include swap_direction(margin, 15px 0);
        @include swap_direction(padding, 0px 20px);
        background: url(/media/images/checkout/icon_safe_lock.png) no-repeat;
        background-size: 12px 12px;
        background-position: 0px 0px;
        line-height: 15px;
        display: inline-block;
        vertical-align: top;
        font-size: 12px;
        font-weight: bold;
      }
      .bb_copyright {
        @include swap_direction(padding, 0 20px 50px 20px);
        background-color: $color-gray-background;
        text-align: center;
      }
      &__wrap > {
        .block-template-basic-responsive-v1 {
          margin-bottom: 0;
        }
      }
      .site-footer__wrap {
        padding-bottom: 120px;
        @if $disable-bbv2-in-checkout == true {
          padding-bottom: 65px;
        }
      }
    }
    .newsletter-info__text {
      @include swap_direction(margin, 0 0 20px 0);
      &--new,
      &--provid {
        @include swap_direction(margin, 20px 0 0 0);
      }
    }
    .checkout {
      .expando-block {
        border-bottom: none;
      }
    }
  }
  .opc__cart,
  .opc__shipping,
  .opc__samples,
  .opc__payment,
  .opc__signin,
  .opc__review,
  .opc__confirm {
    .content {
      .sticky-chat,
      .sticky-offer {
        display: none !important;
      }
    }
    .checkout_sticky {
      border-top: 2px solid $color-gray-border;
      box-shadow: 0px 0px 10px $color-gray-border;
      .mobile_sticky_bottom {
        background-color: $color-pink;
      }
      .disabled {
        background-color: $color-light-gray;
      }
      input[type='submit'] {
        height: 40px;
        line-height: initial;
      }
    }
    .checkout__sidebar {
      #offer-code-panel {
        @include swap_direction(padding, 15px 20px 0 20px);
        margin: 0px;
        border-top: 1px solid $color-black;
        .offer-code-panel__content {
          padding: 0;
          #offer_code .offer_code_box {
            em.offer-code-message {
              display: none;
            }
            em#one-offer-only span {
              float: $ldirection;
            }
          }
          .offer_submit {
            @include swap_direction(margin, 0 0 25px 0);
          }
        }
        .offer_message {
          @include swap_direction(margin, 0 0 10px 0);
          color: $color-green;
        }
      }
      .offer_code_box {
        input[type='text'] {
          margin-bottom: 0;
        }
        span {
          display: block;
          margin-bottom: 10px;
        }
      }
      #links-panel {
        .expando-block__content {
          padding: 0px;
          margin: 0px;
          .links_list {
            .link {
              border: 0px;
              padding-#{$ldirection}: 0px;
            }
          }
        }
      }
    }
    .checkout__panel-header {
      @include h24;
      @include swap_direction(margin, 10px 0 15px 0);
      font-size: 16px;
      letter-spacing: 0.1em;
      border: 0;
      padding: 0;
    }
    .checkout {
      input[type='text'],
      input[type='tel'],
      input[type='password'],
      select {
        @include swap_direction(padding, 5px 15px);
        width: 100%;
        border: 1px solid $color-black;
        height: calc(3em - -2px);
        margin-bottom: 15px;
        &.error {
          border: 1px solid $color-red;
        }
      }
      select {
        .touch &,
        .touchevents & {
          @include swap_direction(padding, 5px 30px 5px 13px);
          background: url(/media/images/global/icon-dropdown.png) no-repeat $rdirection 4px top 15px;
        }
      }
      .phone_label_container {
        @include swap_direction(margin, 15px 0);
        padding: 0;
      }
      &.checkout-confirmation-page,
      &.samples,
      &.viewcart {
        margin-#{$rdirection}: 0;
        margin-#{$ldirection}: 0;
      }
    }
    .column {
      @include swap_direction(padding, 0 20px);
      margin: 0;
      &.checkout__sidebar {
        padding: 0;
      }
    }
    .bold-text {
      font-weight: bold;
      text-transform: capitalize;
    }
    .cart-item {
      &__price-label {
        display: inline-block;
      }
      &__product-name {
        padding-#{$rdirection}: 30px;
      }
    }
    .checkout {
      .legal_txt,
      .privacy_policy_container {
        label {
          display: table;
        }
        input[type='checkbox'] {
          & ~ .label::before,
          & ~ label::before {
            display: table-cell;
            vertical-align: top;
            border-spacing: 16px;
            border-#{$rdirection}: 7px solid transparent;
          }
        }
      }
    }
  }
  .opc__payment,
  .opc__signin,
  .opc__shipping,
  .opc__review {
    .checkout__content {
      .checkout__panel {
        .offer-code-panel__title {
          background: none;
          font-size: 14px;
          text-align: $ldirection;
        }
      }
    }
    .checkout {
      .email_promotions {
        @include swap_direction(padding, 0px);
        .newsletter-info,
        .sms-promotions-container {
          display: block;
          border-bottom: none;
          .expando-block__content {
            @include swap_direction(padding, 0px 20px);
            background-color: $color-white;
          }
        }
        .newsletter-info__header,
        .h_sms_promotions {
          @include swap_direction(padding, 15px 20px);
          @include swap_direction(margin, 0px);
          border-top: 1px solid $color-black;
          background-color: $color-gray-background;
        }
      }
      #emailpromo-info-panel {
        border-bottom: none;
      }
    }
  }
  .opc__cart,
  .opc__payment,
  .opc__shipping,
  .opc__review {
    .checkout__content {
      .cart-items {
        .cart-item {
          &__qty {
            margin: 0;
            padding: 0;
          }
          &__qty-label {
            display: none;
          }
          &__total {
            display: block;
            float: $rdirection;
            font-weight: 600;
            margin: 0;
            padding: 0;
          }
          &__price--mobile-only {
            display: block !important;
            visibility: visible;
          }
          &__desc-info {
            display: flex;
            flex-direction: column;
          }
          &__price {
            order: 4;
            margin-bottom: 10px;
          }
        }
      }
    }
    .full_space_column {
      padding: 20px;
    }
    #order_status {
      .expando-block {
        &.expando-block--expanded {
          .expando-block__content {
            height: auto;
          }
        }
        .expando-block__content {
          height: 0;
        }
      }
    }
    .checkout {
      .expando-block {
        &.expando-block--expanded {
          .expando-block__content {
            width: 100%;
          }
          .expando-block__icon {
            transform: rotate(180deg);
          }
        }
        .expando-block__icon {
          transform: rotate(90deg);
        }
      }
    }
    .summary_section_separater {
      border-bottom: 0;
    }
    .newsletter-info,
    .sms-promotions {
      h3,
      &__header {
        text-indent: 0;
      }
    }
    input[type='radio'] {
      &:focus + label {
        outline: 1px dotted $color-white;
      }
    }
    .custom-outline {
      outline: 1px dotted $color-white !important;
    }
    .newsletter-info,
    .sms-promotions-container,
    .gift-wrapper-block,
    .links-panel,
    .order-summary-panel {
      &.expando-block--expanded {
        .header-border__content {
          border-bottom: 1px solid $color-black;
        }
      }
    }
    .mobile_email_signup {
      margin-top: 15px;
    }
  }
}
