@mixin opc__updates__checkout-review {
  .opc__review {
    #checkout_review {
      .checkout-buttons-container {
        a {
          width: 93% !important;
        }
      }
    }
    .checkout-buttons-container {
      &.trans_detail_item {
        width: auto;
      }
    }
    .remove_link {
      font-size: 25px;
      border: 0;
      position: absolute;
      top: 0;
      #{$rdirection}: -15px;
    }
    .checkout__content {
      .cart-item__total {
        display: inline-block;
        float: $rdirection;
        padding-#{$rdirection}: 0;
      }
      #viewcart-panel {
        .viewcart-panel__content {
          margin-top: 0;
          .qty-label {
            display: none;
          }
          .checkout__subtitle {
            padding-bottom: 15px;
          }
          .color {
            @include swap_direction(padding, 5px 0 10px 0);
          }
        }
      }
      .shipping-panel,
      .order-summary-panel {
        margin: 0;
        padding: 0;
        border-bottom: 0;
      }
    }
    #review-address-info {
      .change_link {
        float: $rdirection;
        padding-top: 32px;
      }
      &.expando-block__content {
        height: auto;
      }
    }
    .shipping-panel {
      border-bottom: 0;
    }
    .full_divider {
      padding: 15px;
      border-bottom: 1px solid $color-black;
    }
    .viewcart-panel {
      border-top: 0;
      border-bottom: 0;
    }
    .order-summary-panel {
      background: $color-gray-background;
      &__title {
        @include swap_direction(padding, 16px 15px);
        border-bottom: 1px solid $color-gray-border;
      }
      .orders_items_text {
        display: none;
      }
      .order-summary__total {
        &-label,
        &-value {
          border-top: 1px solid $color-gray-border;
        }
      }
      .order_status {
        display: flex;
        flex-direction: column;
      }
      .checkout__sidebar {
        order: 2;
      }
      .viewcart-panel {
        order: 3;
      }
    }
    .view-cart-panel__title {
      @include swap_direction(padding, 0 10px);
    }
    .checkout__sidebar {
      #offer-code-panel {
        margin: 0;
        padding: 10px;
        border-top: 0;
        background: $color-gray;
      }
      #links-panel {
        margin-bottom: 0px;
      }
    }
    .order-summary__content {
      padding: 10px;
    }
    .checkout {
      .expando-block {
        border-bottom: 0;
      }
      .email_promotions {
        border-bottom: 1px solid $color-black;
      }
    }
    .shipping-panel__title {
      display: block;
      text-align: center;
      background: none;
      font-size: 21px;
      border: none;
      letter-spacing: 0.1em;
      line-height: 25px;
    }
    .order__panel-title {
      padding-#{$ldirection}: 15px;
      font-size: 16px;
    }
    .promotions-container {
      .newsletter-info__item,
      .sms-promotions__item {
        @include swap_direction(padding, 15px 0 15px 0);
        margin: 0;
      }
    }
    #review-instructions {
      p.column {
        padding-bottom: 10px;
      }
    }
    #promo-message {
      padding-bottom: 20px;
      display: inline;
    }
  }
}
