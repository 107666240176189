#address_form_container {
  .address-consequence-info {
    margin-top: -30px;
    margin-bottom: 30px;
  }
}

.site-email-signup__form,
.site-utils__dropdown__content {
  .tooltip {
    display: none;
  }
  .tooltiptext-under {
    display: none;
  }
}

.reg-dropdown {
  color: #ffffff !important;
  margin-top: 10px;
}

#bt_notification_container {
  .content {
    #bt_notification {
      .bt-content.bt-bottom-right {
        z-index: 10000;
      }
    }
  }
}
